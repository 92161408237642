/* Coverage:
 Statements: 44%
 Branches: 100%
 Functions: 34%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import clsx from 'clsx'
import type { ToastOptions as BaseToastOptions, Theme, ToastContent, TypeOptions } from 'react-toastify'
import { ToastContainer as DefaultToastContainer, Slide, toast as toastify } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Define our local options interface
interface ToastOptions {
  autoClose?: number | false
  content: ToastContent
  theme?: Theme
  type?: TypeOptions
  toastId?: string
  closeOnClick?: boolean
  style?: any
  role?: 'alert' | 'status'
  'aria-live'?: 'assertive' | 'polite'
  'aria-label'?: string
}
interface ToastContainerProps {
  className?: string
}
export function ToastContainer(props: ToastContainerProps) {
  return (
    <DefaultToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Slide}
      className={clsx('text-sm', props.className)}
      toastClassName={clsx(
        'relative flex p-1 min-h-10 rounded-lg justify-between overflow-hidden cursor-pointer',
        'data-[type=success]:bg-success data-[type=error]:bg-danger data-[type=info]:bg-primary data-[type=warning]:bg-warning',
      )}
      closeButton={({ closeToast }) => (
        <button
          onClick={closeToast}
          className="flex items-center justify-center w-6 h-6 ml-2"
          aria-label="Close notification"
        >
          ×
        </button>
      )}
    />
  )
}
export function toast(options: ToastOptions) {
  const { content, type, autoClose = 5000, theme = 'colored', toastId, closeOnClick = true, style = {} } = options
  const toastOptions: BaseToastOptions = {
    position: 'top-right' as const,
    autoClose,
    theme,
    toastId,
    closeOnClick,
    style: {
      fontSize: '0.875rem',
      ...style,
    },
  }
  switch (type) {
    case 'info':
      return toastify.info(content, toastOptions)
    case 'success':
      return toastify.success(content, toastOptions)
    case 'warning':
      return toastify.warn(content, toastOptions)
    case 'error':
      return toastify.error(content, toastOptions)
    default:
      return toastify.info(content, toastOptions)
  }
}
